<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>优惠券</el-breadcrumb-item>
      <el-breadcrumb-item>商家注册</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div class="main">
        <el-form
          :model="query"
          ref="queryRef"
          :rules="formRules"
          label-width="110px"
        >
          <el-form-item label="用户名" prop="username">
            <el-input
              v-model="query.username"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="query.password"
              placeholder="请输入密码"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="重复密码" prop="password1">
            <el-input
              @change="verifiers"
              v-model="query.password1"
              placeholder="请输入重复密码"
              show-password
            ></el-input>
          </el-form-item>
          <el-row
            v-for="(item, index) in query.maxConfigCouponMoneyJson"
            :key="index"
          >
            <el-col :span="12">
              <el-form-item label="停车场">
                <span>{{ item.pkName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最高优惠额度">
                <el-input
                  type="number"
                  v-model="item.maxConfigCouponMoney"
                  placeholder="请输入最高优惠额度"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="商家名称" prop="shopName">
            <el-input
              v-model="query.shopName"
              placeholder="请输入商家名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="linkMan">
            <el-input
              v-model="query.linkMan"
              placeholder="请输入联系人"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="tel">
            <el-input
              v-model="query.tel"
              placeholder="请输入联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input
              v-model="query.address"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input
              type="textarea"
              v-model="query.remarks"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button>取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import { findPkInfoSelect } from '@/api/Parkinglot.js'
import { shopInfo } from '@/api/coupon.js'
export default {
  data() {
    var checkphone = (rule, value, callback) => {
      // let phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else if (!this.isCellPhone(value)) {
        // 引入methods中封装的检查手机格式的方法
        callback(new Error('请输入正确的手机号!'))
      } else {
        callback()
      }
    }
    var validatePass1 = (rule, value, callback) => {
      let result
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        var express =
          /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
        result = express.test(value) // 满足条件时候 正则结果是true
        if (result) {
          callback()
        } else {
          callback(new Error('最少8个字符，需包含大、小写字母、特殊字符和数字'))
        }
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.query.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      query: {
        username: undefined, // 账号
        password: undefined, // 密码
        password1: undefined, // 密码
        maxConfigCouponMoneyJson: [
          {
            pkName: undefined,
            pkIds: undefined, // 停车场
            maxConfigCouponMoney: undefined,
          },
        ],
        linkMan: undefined, // 联系人
        shopName: undefined, // 商家名称
        tel: undefined, // 联系电话
        address: undefined, // 详细地址
        remarks: undefined, // 备注
      },
      formRules: {
        // 添加表单的验证规则
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [
          { required: true, validator: validatePass1, trigger: 'blur' },
        ],
        password1: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
        pkIds: [
          { required: true, message: '请选择停车场名称', trigger: 'blur' },
        ],
        maxConfigCouponMoney: [
          { required: true, message: '请输入最高优惠额度', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
        shopName: [
          { required: true, message: '请输入商家名称', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
        linkMan: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
        tel: [
          {
            required: true,
            validator: checkphone,
            trigger: ['blur', 'change'],
          },
        ],
      },
      PkInfoSelect: [], // 保存停车场下拉框
      maxConfigCouponMoneyJsons: undefined,
    }
  },
  created() {
    this.findPkInfoSelect()
  },
  methods: {
    isCellPhone(val) {
      let mobileReg =
        /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/
      if (!mobileReg.test(val)) {
        return false
      } else {
        return true
      }
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          this.maxConfigCouponMoneyJsons = this.PkInfoSelect.map((item) => {
            return {
              pkName: item.pkName,
              pkIds: item.id,
              maxConfigCouponMoney: undefined,
            }
          })
          this.query.maxConfigCouponMoneyJson = JSON.parse(
            JSON.stringify(this.maxConfigCouponMoneyJsons)
          )
        }
      })
    },
    verifiers() {
      // 密码校验
      if (this.query.password !== this.query.password1) {
        this.$message.warning('两次密码不一致')
      }
    },
    onSubmit() {
      this.$refs.queryRef.validate((valid) => {
        if (!valid) return
        if (this.query.password !== this.query.password1) {
          return this.$message.warning('请输入相同的密码')
        }
        this.query.maxConfigCouponMoneyJson =
          this.query.maxConfigCouponMoneyJson.filter(
            (item) => item.maxConfigCouponMoney
          )
        if (this.query.maxConfigCouponMoneyJson.length == 0) {
          this.query.maxConfigCouponMoneyJson = JSON.parse(
            JSON.stringify(this.maxConfigCouponMoneyJsons)
          )
          return this.$message.warning('所属停车场必填')
        }

        shopInfo(this.query).then((res) => {
          if (res.data.code === '200') {
            if (res.data.data === false) {
              this.$message.warning('商家用户名重复，请重新注册')
            } else {
              this.$refs.queryRef.resetFields()
              this.$message.success('注册成功')
            }
          }
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.main {
  width: 50%;
}
</style>
